/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(22, 28%, 21%);
  --clr-primary-2: hsl(22, 28%, 29%);
  --clr-primary-3: hsl(22, 28%, 37%);
  --clr-primary-4: hsl(22, 28%, 45%);
  /* primary/main color */
  --clr-primary-5: hsl(22, 31%, 52%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(22, 31%, 60%);
  --clr-primary-7: hsl(22, 31%, 67%);
  --clr-primary-8: hsl(20, 31%, 74%);
  --clr-primary-9: hsl(22, 31%, 81%);
  --clr-primary-10: hsl(22, 31%, 88%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/


*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.contactpage{
  width: 100vw;
  min-height: 100vh;
}
a{
  text-decoration: none!important;
}
ul{
  margin: 0;
  padding: 0!;
}
.kame-cover{
width: 100%;
/* margin-bottom: 220px; */

}
/* .kame-cover img{
  width: 100%;
} */
.homeintro{
  width: 100vw;
   height: 100vh;
   /* animation: show 1s linear; */
}
.gokuverseintro{
  width: 100vw;
  height: 100vh;
}
.introproductionwork{
  width: 100vw;
  height: 40vh;
  position: relative;



}
.blogintro{
  width: 100vw;
  height: 40vh;
  position: relative;
}
.call_button{
  position: fixed;
  bottom: 2%;
  left: 2%;
z-index: 90000;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgb(227, 105, 66);
  padding: 12px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  transition: all linear 0.1s;
}
.call_button a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.call_button:hover{
  scale: 1.08;
}
.call_button a img{
  width: 75%;
  height: 75%;
  border-radius: 50%;
}
@media screen and (max-width: 1596px) {
.call_button{
width: 60px;
height: 60px;
}
}
@media screen and (max-width: 1024px) {
  .homeintro{
    height: 75vh;
    
  }
  .gokuverseintro{
    height: 75vh;
    background-position-x: center;
    
  }
  .introproductionwork{
    height: 34vh;
  }
  .blogintro{
    width: 100vw;
    height: 30vh;
    position: relative;
  }
  .call_button{
    width: 50px;
    height: 50px;
    left: 4%;
  }
  
}
@media screen and (max-width: 769px) {
  .call_button{
    width: 40px;
    height: 40px;
  }
  .homeintro{
    height: 65vh;
  }
  .gokuverseintro{
    height: 65vh;
    
  }
  .introproductionwork{
    height: 30vh;
  }
  
}
@media screen and (max-width: 596px) {
  .homeintro{
    height: 55vh;
  }
  .gokuverseintro{
    height: 55vh;
  }
  .introproductionwork{
    height: 30vh;
  }
  .pagination .page-item:nth-child(1) a{
    /* font-size: 8px!important; */
    /* padding: 4px; */
    /* display: flex!important; */


  }
  .pagination .page-item a{
    font-size: 12px!important;


  }
  .call_button{
    width: 40px;
    height: 40px;
    left: 3%;
  }
  .call_button img {
    width: 90%;
    height: 90%;
  }
  
}
@media screen and (max-width: 424px) {
 
  .introproductionwork{
    height: 30vh;
  }
  .blogintro{
    width: 100vw;
    height: 40vh;
    position: relative;
  }
  
}



.background{
  position: absolute;
  width: 100%;
  /* height: 500px; */
  top: 0;
  left: 0;
  z-index: 2;
  /* background-color: red; */
  height: 100%;
  
}

.aboutus{
  width: 100vw;
  /* position: relative; */

}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}
@font-face {
	font-family: "Trajan";
	src: local("Trajan"), url("./assets/Fonts/00186-UTM-Trajan-Pro-Bold.ttf") format ("truetype");
	font-weight: bold
}
@font-face {
	font-family: "SVN-Gotham Bold";
	src: local("SVN-Gotham Bold"), url("./assets/Fonts/SVN-Gotham Bold.ttf") format ("truetype");
	font-weight: bold;
  font-display: swap;
}
@font-face {
	font-family: "SVN-Gotham Light";
	src: local("SVN-Gotham Light"), url("./assets/Fonts/SVN-Gotham Light.ttf") format ("truetype");
	font-weight: light;
  font-display: swap;
}
@font-face {
	font-family: "SF-Pro-Display-Regular";
	src: local("SF-Pro-Display-Regular"), url("./assets/Fonts/SF-Pro-Display-Regular.ttf") format("truetype");
	font-weight: light;
  font-display: swap;
}
@font-face {
	font-family: "SF-Pro-Display-Bold";
	src: local("SF-Pro-Display-Bold"), url("./assets/Fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf") format("opentype");
	font-weight: bold;
  font-display: swap;
}
@font-face {
	font-family: "SF-Pro-Display-RegularItalic";
	src: local("SF-Pro-Display-RegularItalic"), url("./assets/Fonts/SF-Pro-Display/SF-Pro-Display-RegularItalic.otf") format("opentype");
	font-weight: light;
  font-display: swap;
}
@font-face {
	font-family: "SF-Pro-Display-Medium";
	src: local("SF-Pro-Display-Medium"), url("./assets/Fonts/SF-Pro-Display/SF-Pro-Display-Medium.otf") format("opentype");
  font-display: swap;
	
}
@font-face {
	font-family: "SF-Pro-Display-Light";
	src: local("SF-Pro-Display-Light"), url("./assets/Fonts/SF-Pro-Display/SF-Pro-Display-Light.otf") format("opentype");
  font-display: swap;
	
}
body {
  font-family: SF-Pro-Display-Regular;
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
  /* min-height: 100px; */
  /* max-height: 100vh; */
  /* max-width: 100vw; */
  /* overflow: hidden; */
}
.app{
  /* width: 100vw; */
  /* height: 100vh; */
  /* position: relative; */
}
.show{
  /* animation: show 0.7s linear; */
}
.page-goku{
  width: 100vw;
}
.footer{
  min-height: 580px;
background-color: antiquewhite;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
hr {
  border: none;
  border-top: 1px solid var(--clr-grey-8);
}
h1,
h2,
h3,
h4,
h5 {
  letter-spacing: var(--spacing);
  /* text-transform: capitalize; */
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-3);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  padding: 5rem 0;
}
.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
.text-center {
  text-align: center;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
@keyframes scaleIn {
  from {
      transform: scale(.5, .5);
      opacity: .1;
  }
  to {
      transform: scale(20.5, 20.5);
      opacity: 0;
  }
}
@keyframes Nhay {
  0% {
      transform: translateY(0);
      
  }
  50% {
      transform: translateY(20px);
     
  }
  100%{
    transform: translateY(0px);
      
  }
}

@keyframes show {
  0% {
      opacity:0;
    /* transform: scale(0); */

    
      
      
  }
  50% {
      
    opacity:0.5;
    /* transform: scale(0.5); */

     
  }
  100%{
    opacity:1;
    /* transform: scale(1); */

      
  }
}

@keyframes slideinright {
  0% {
      opacity:0;
      transform: translateX(-100%);
      
      
  }
  50% {
      
    opacity:0.5;
    transform: translateX(-50%);


     
  }
  100%{
    opacity:1;
    transform: translateX(0);

      
  }
}


.loading {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 10rem;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: var(--clr-primary-5);
  animation: spinner 0.6s linear infinite;
}

.btn {
  text-transform: uppercase;
  background: var(--clr-primary-5);
  color: var(--clr-primary-10);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 400;
  transition: var(--transition);
  font-size: 0.875rem;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
  border-color: transparent;
}
.btn:hover {
  color: var(--clr-primary-1);
  background: var(--clr-primary-7);
}
.title {
  text-align: center;
}
.title .underline {
  width: 6rem;
  height: 0.25rem;
  background: #49a6e9;
  background: var(--clr-primary-5);
  margin-left: auto;
  margin-right: auto;
}
.page-100 {
  min-height: calc(100vh - 10rem);
  padding: 5rem 0;
}
.page {
  min-height: calc(100vh - (20vh + 10rem));
}
.texterror{
  background: linear-gradient(
        274.38deg,
        #ce8f31 0%,
        #f6e493 50%,
        #efd45a 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
}
.backerror:hover{
  opacity: 0.8;

}
