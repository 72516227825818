$background-color: rgba(
  $color: wheat,
  $alpha: 0.1,
);

// $background-color: #F4F5F6;

.Profile_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;

  a {
    color: black;
    cursor: pointer;
  }

  .Profile_menu {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    .Profile_menu_text {
      padding: 20px;
    }
  }

  .Profile_center {
    // position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    .Profile_center_bg {
      position: absolute;
      width: 100%;
      justify-content: center;
      display: flex;
      z-index: -1;

      .profile_page1_bg {
        width: 50%;
      }
    }
    .Profile_center_text {
      position: absolute;
      width: 100%;
      justify-content: center;
      display: flex;
      z-index: 2;

      padding-top: 12.5px;
      padding-left: 12.5px;

      .profile_page1_text {
        width: 25%;
      }
    }
  }

  .Profile_bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .Profile_bottom_circle_bottom_bg {
      position: absolute;
      width: 100%;
      bottom: 0;
      .circle_bottom_bg {
        width: 100%;
      }
    }

    .Profile_bottom_illustration {
      position: absolute;
      width: 100%;
      bottom: 0;
      display: flex;
      justify-content: center;
      .illustration {
        width: 55%;
      }
    }
  }

  .Profile_cdt {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    // background: red;
    .cdt_icon {
      position: absolute;
      // width: 100%;
      // transform: translateX(-50%) translateY(-50%);
      img {
        width: 70%;
        // height: 100%;
      }
    }
  }

  .Profile_goku_img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    .Profile_goku_img_left {
      position: absolute;
      top: 35%;
      left: 15%;
      .profile_page1_goku_img_left {
        width: 100%;
        border-radius: 22px;
        box-shadow: -10px 10px #e0eef7;
      }
    }
    .Profile_goku_img_right {
      position: absolute;
      top: 20%;
      left: 75%;
      .profile_page1_goku_img_right {
        width: 100%;
        border-radius: 24px;
        box-shadow: 10px 10px #e0eef7;
      }
    }
  }

  .Profile_project {
    width: 150px;
    height: 150px;
    border-radius: 30px;
    background: #d3eedc;
    position: absolute;
    top: 10%;
    left: 10%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-weight: bold;

    h1 {
      font-weight: bold;
    }
  }

  .Profile_contact {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    border: 1px solid rgba($color: white, $alpha: 0.2);
    padding: 24px;
    top: 80%;
    left: 50%;
    background-color: rgba($color: white, $alpha: 0.1);
    backdrop-filter: blur(16px);
    transform: translateX(-50%) translateY(-50%);

    .Profile_contact_img {
      .profile_page1_goku_icon {
      }
    }

    .Profile_contact_content {
      color: white;
      padding: 0 20px;
      h3 {
      }
      span {
      }
    }

    .Profile_contact_contact {
      color: white;
      background-color: black;
      border-radius: 40px;
      // cursor: pointer;
      a {
        padding: 8px 20px;
        text-decoration: underline !important;
        // text-decoration: overline;
        color: white;
      }
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page2_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background-color;

  .Profile_page2_nav {
    // position: absolute;
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #0071e3;
    span {
      color: white;
    }
  }

  .Profile_page2_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .Profile_page2_body_tv {
      width: 100%;
      // padding-top: 2%;
      align-items: center;
      display: flex;
      justify-content: center;
      .profile_page2_tv {
        object-fit: contain;
        width: 80%;
      }
    }

    .Profile_page2_body_camera {
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      .profile_page2_camera {
        width: 15%;
        object-fit: contain;
        // mix-blend-mode: color-burn;
      }
    }

    .Profile_page2_body_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;

      .page2_body_content_title {
        font-size: 5em;
        background: linear-gradient(
          to right,
          #2ca2b4 0%,
          #5598de 25%,
          #7f87ff 50%,
          #f65aad 75%,
          #ec3d43 100%
        );
        background-clip: text;
        color: transparent;
      }

      .page2_body_content {
        width: 45%;
        color: #777e90;
        font-weight: bold;
        // font-family: 'SF Pro';
      }

      .page2_body_content_since {
        color: #777e90;
        font-style: italic;
        font-size: 14px;
      }
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page3_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background-color;
  overflow: auto;

  .Profile_page3_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    .Profile_page3_title {
      // line-height: 64px;
      h1 {
        font-size: 4em;
        font-weight: bold;
        line-height: 50px;
      }

      p {
        margin-top: 20px;
        font-size: 20px;
      }
    }

    .Profile_page3_body {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 4% 10%;

      .page3_body_content {
        display: flex;
        flex-direction: column;
        justify-content: start;
        text-align: left;
        background-color: white;
        margin: 20px;
        padding: 30px 20px;
        border-radius: 18px;
        box-shadow: 5px 5px 5px rgba($color: black, $alpha: 0.1);
        width: 70%;

        .page3_body_content_icon {
          margin-bottom: 20px;
        }

        p {
          font-weight: 600;
          font-size: 20px;
        }
      }
    }

    .Profile_more_btn {
      padding: 12px 20px;
      color: white;
      border-radius: 999px;
      background-color: #0071e3;
      cursor: pointer;
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page4_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;

  .Profile_page4_container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    // padding: 100px 100px;

    align-items: center;
    padding: 150px;

    img {
      padding: 0 5px;
      width: 50%;
    }
  }

  .Profile_page4_footer {
    position: absolute;
    top: 95%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    p {
      color: #0066cc;
      span {
        font-weight: 600;
      }
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page5_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background-color;

  .Profile_page5_container {
    // position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 4em;
      background: linear-gradient(
        to right,
        #1e3791 0%,
        #2948b1 33%,
        #3153c6 66%,
        #385fda 100%
      );
      background-clip: text;
      color: transparent;
    }

    img {
      width: 80%;
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page6_wrapper,
.Profile_page8_wrapper,
.Profile_page10_wrapper,
.Profile_page13_wrapper,
.Profile_page15_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background-color;

  .Profile_page6_container,
  .Profile_page8_container,
  .Profile_page10_container,
  .Profile_page13_container,
  .Profile_page15_container {
    // position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
    }

    p {
      color: #0066cc;
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);

      span {
        font-weight: bold;
      }
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page7_wrapper,
.Profile_page11_wrapper,
.Profile_page16_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  // background-color: $background-color;

  .Profile_page7_container,
  .Profile_page11_container,
  .Profile_page16_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    .Profile_page7_title,
    .Profile_page11_title,
    .Profile_page16_title {
      // line-height: 64px;
      h1 {
        font-size: 4em;
        font-weight: bold;
        line-height: 50px;
      }

      p {
        margin-top: 20px;
        font-size: 20px;
      }
    }

    .Profile_page7_body,
    .Profile_page11_body,
    .Profile_page16_body {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 4% 10%;

      .page7_body_content,
      .page11_body_content,
      .page16_body_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
        background-color: white;
        margin: 10px;
        padding: 0px 0px;
        width: 70%;

        .page7_body_content_img,
        .page11_body_content_img,
        .page16_body_content_img {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
          width: 100%;
          img {
            width: 90%;
            border-radius: 16px;
          }
        }

        p {
          font-weight: 600;
          font-size: 20px;
        }

        span {
          color: #777e90;
        }
      }
    }

    .Profile_more_btn {
      padding: 12px 20px;
      color: white;
      border-radius: 999px;
      background-color: #0071e3;
      cursor: pointer;
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page9_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background-color;

  .Profile_page9_container_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 50px;
    .Profile_page9_container_left {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      // padding: 0 20px;

      .Profile_page9_title {
        // line-height: 64px;
        h1 {
          font-size: 4em;
          font-weight: bold;
          line-height: 50px;
        }

        p {
          margin-top: 20px;
          font-size: 20px;
        }
      }

      .Profile_more_btn {
        padding: 12px 20px;
        color: white;
        border-radius: 999px;
        background-color: #0071e3;
        cursor: pointer;
      }
    }

    .Profile_page9_container_right {
      width: 50%;
      height: 100%;
      //  padding: 0 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .page9_container_right_top {
        text-align: center;
        .page9_container_right_top_img {
          img {
            width: 85%;
          }
        }
        p {
          font-weight: bold;
          padding: 10px 0;

          span {
            padding-left: 10px;
            font-weight: normal;
            font-size: 14px;
            color: #777e90;
          }
        }
      }

      .page9_container_right_bottom {
        display: flex;
        justify-content: space-evenly;
        text-align: center;

        .page9_container_right_bottom_content {
          // line-height: 0px;
          .page9_container_right_bottom_img {
            img {
              width: 80%;
            }
          }

          p {
            font-weight: bold;
            // padding: 10px 0;
            padding-top: 10px;
            margin-bottom: 0;
          }

          span {
            font-weight: normal;
            font-size: 14px;
            color: #777e90;
          }
        }
      }
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page12_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background-color;

  .Profile_page12_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    .Profile_page12_title {
      // line-height: 64px;
      h1 {
        font-size: 4em;
        font-weight: bold;
        line-height: 50px;
      }

      p {
        margin-top: 20px;
        font-size: 20px;
      }
    }

    .Profile_page12_body {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      padding: 0% 10%;

      .page12_body_content:nth-child(4) {
        grid-column: 2;
      }

      .page12_body_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        margin: 10px;
        width: 100%;

        .page12_body_content_img {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
          width: 80%;
          img {
            width: 90%;
            border-radius: 16px;
          }
        }

        p {
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 0px;
        }

        span {
          color: #777e90;
        }
      }
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page14_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background-color;

  .Profile_page14_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    .Profile_page14_title {
      // line-height: 64px;
      h1 {
        font-size: 4em;
        font-weight: bold;
        line-height: 50px;
      }

      p {
        margin-top: 20px;
        font-size: 20px;
      }
    }

    .Profile_page14_body {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      padding: 0% 10%;

      .page14_body_content:nth-child(3) {
        // grid-column: 1/3;
      }

      .page14_body_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        margin: 10px;
        width: 100%;

        .page14_body_content_img {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
          width: 100%;
          img {
            width: 90%;
            border-radius: 16px;
          }
        }

        p {
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 0px;
        }
      }
    }
    .Profile_more_btn {
      padding: 12px 20px;
      color: white;
      border-radius: 999px;
      background-color: #0071e3;
      cursor: pointer;
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page17_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background-color;

  .Profile_page17_container {
    // position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 5%;

    h1 {
      font-size: 4em;
      background: linear-gradient(
        to right,
        #1e3791 0%,
        #2948b1 33%,
        #3153c6 66%,
        #385fda 100%
      );
      background-clip: text;
      color: transparent;
      padding-bottom: 20px;
    }

    .Profile_page17_body {
      width: 100%;
      background: white;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      padding: 50px;

      h2 {
        width: 60%;
        text-align: center;

        a {
          text-decoration: underline !important;
        }
      }

      .page17_body_imac_content {
        position: relative;
        width: 50%;
        height: 100%;

        .profile_imac_iframe {
          width: 100%;
          height: 500px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          z-index: 0;
          // padding-bottom: 18%;
          // padding-top: 1%;
          iframe {
            // position: absolute;
            // padding-top: 20px;
            width: 80%;
            height: 100%;
            padding: 3.5%;
          }
        }

        .profile_imac_img {
          width: 100%;
          height: 500px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;

          // position: absolute;
          img {
            // position: absolute;
            // padding-top: 20px;
            width: 80%;
          }
        }
      }
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page18_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background-color;

  .Profile_page18_container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 7%;

    .page18_content_left {
      width: 40%;
      text-align: right;
      padding-top: 50px;

      h1 {
        font-weight: bold;
      }

      p {
        padding-left: 5%;
        a {
          text-decoration: underline !important;
        }
      }
    }

    .page18_content_right {
      width: 60%;

      img,
      video {
        width: 100%;
      }
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page19_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background-color;

  .Profile_page19_container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 7%;

    .page19_content_left {
      position: relative;
      width: 60%;
      height: 100%;

      img,
      video {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      img:nth-child(1) {
        z-index: 1;
      }

      img:nth-child(2) {
        padding: 30px 40px;
      }

      video {
        padding: 4% 8%;
        object-fit: fill;
      }
    }

    .page19_content_right {
      width: 40%;
      text-align: left;
      padding-left: 50px;

      h1 {
        font-weight: bold;
      }

      p {
        padding-right: 5%;
      }

      .page19_right_btn_container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
        height: 50%;

        .profile_page19_btn {
          display: flex;
          align-items: center;
          text-align: center;
          cursor: pointer;

          .profile_page19_btn_left {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid black;
          }

          h3 {
            padding-left: 5%;
            font-weight: bold;
          }
        }

        .btn_left_active,
        .profile_page19_btn:hover {
          .profile_page19_btn_left {
            background: linear-gradient(to right, #2e92ee 0%, #be6bff 100%);
          }

          h3 {
            background: linear-gradient(to right, #2e92ee 0%, #be6bff 100%);
            background-clip: text;
            color: transparent;
          }
        }
      }
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page23_wrapper,
.Profile_page30_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background-color;

  .Profile_page23_container,
  .Profile_page30_container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 7%;

    .page23_content_left,
    .page30_content_left {
      position: relative;
      width: 60%;
      height: 100%;

      img,
      video {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      img:nth-child(1) {
        z-index: 1;
      }

      img:nth-child(2) {
        padding: 30px 40px;
      }

      video {
        padding: 4% 8%;
        object-fit: fill;
      }
    }

    .page23_content_right,
    .page30_content_right {
      width: 40%;
      text-align: right;
      padding-right: 50px;
      // align-items: end;

      h1 {
        font-weight: bold;
        width: 100%;
      }

      p {
        padding-left: 5%;
      }

      .page23_right_btn_container,
      .page30_right_btn_container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: end;
        width: 100%;
        height: 50%;

        .profile_page23_btn,
        .profile_page30_btn {
          display: flex;
          align-items: center;
          text-align: right;
          justify-content: end;
          width: 100%;
          cursor: pointer;

          .profile_page23_btn_left,
          .profile_page30_btn_left {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid black;
            margin-left: 5%;
          }

          h3 {
            font-weight: bold;
          }
        }

        .btn_left_active,
        .profile_page23_btn:hover,
        .profile_page30_btn:hover {
          .profile_page23_btn_left,
          .profile_page30_btn_left {
            background: linear-gradient(to right, #2eee86 0%, #26beee 100%);
          }

          h3 {
            background: linear-gradient(to right, #2eee86 0%, #26beee 100%);
            background-clip: text;
            color: transparent;
          }
        }
      }
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page26_wrapper,
.Profile_page33_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background-color;

  .Profile_page26_container,
  .Profile_page33_container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 7%;

    .page26_content_left,
    .page33_content_left {
      position: relative;
      width: 60%;
      height: 100%;

      img,
      video {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      img:nth-child(1) {
        z-index: 1;
      }

      img:nth-child(2) {
        padding: 30px 40px;
      }

      video {
        padding: 4% 8%;
        object-fit: fill;
      }
    }

    .page26_content_right,
    .page33_content_right {
      width: 40%;
      text-align: left;
      padding-left: 50px;

      h1 {
        font-weight: bold;
      }

      p {
        padding-right: 5%;
      }

      .page26_right_btn_container,
      .page33_right_btn_container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
        height: 50%;

        .profile_page26_btn,
        .profile_page33_btn {
          display: flex;
          align-items: center;
          cursor: pointer;

          text-align: left;
          justify-content: start;
          width: 100%;

          .profile_page26_btn_left,
          .profile_page33_btn_left {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid black;
            margin-right: 5%;
          }

          h3 {
            font-weight: bold;
          }
        }

        .btn_left_active,
        .profile_page26_btn:hover,
        .profile_page33_btn:hover {
          .profile_page26_btn_left,
          .profile_page33_btn_left {
            background: linear-gradient(to right, #2e92ee 0%, #be6bff 100%);
          }

          h3 {
            background: linear-gradient(to right, #2e92ee 0%, #be6bff 100%);
            background-clip: text;
            color: transparent;
          }
        }
      }
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page35_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background-color;

  .Profile_page35_container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 7%;

    .page35_content_left {
      position: relative;
      width: 60%;
      height: 100%;

      img,
      video {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      img:nth-child(1) {
        z-index: 1;
      }

      img:nth-child(2) {
        padding: 30px 40px;
      }

      video {
        padding: 4% 8%;
        object-fit: fill;
      }
    }

    .page35_content_right {
      width: 40%;
      text-align: right;
      padding-right: 50px;
      // align-items: end;

      h1 {
        font-weight: bold;
        width: 100%;
      }

      p {
        padding-left: 5%;
      }
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page36_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background-color;

  .Profile_page36_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    .Profile_page36_title {
      position: absolute;
      top: 8%;
      h1 {
        font-size: 4em;
        font-weight: bold;
        line-height: 50px;
      }

      p {
        margin-top: 20px;
        font-size: 20px;
      }
    }

    .Profile_page36_body {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 4% 10%;

      .page36_body_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
        background-color: white;
        margin: 10px;
        padding: 0px 0px;
        width: 70%;

        .page36_body_content_img {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
          width: 100%;
          img {
            width: 90%;
            border-radius: 16px;
          }
        }

        p {
          font-weight: 600;
          font-size: 20px;
        }

        span {
          color: #777e90;
        }
      }

      .page36_body_content:nth-child(1) {
        // margin-bottom: 100px;
      }
      .page36_body_content:nth-child(2) {
        margin-top: 150px;
      }
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page38_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background-color;

  .Profile_page38_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    width: 100%;
  }

  img:nth-child(2) {
    width: 40%;
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page39_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: $background-color;

  .Profile_page39_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;

    h1 {
      font-size: 4em;
      font-weight: bold;
    }

    .Profile_page39_container_img {
      padding: 2%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      img:nth-child(1) {
        width: 40%;
        padding: 20px;
      }

      img:nth-child(2) {
        width: 60%;
        padding: 20px;
      }
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

.Profile_page40_wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #141416;

  .Profile_page40_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5%;
    .Profile_page40_container_content {
      width: 100%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      position: relative;

      .profile_page40_bg_img {
        transform: translateX(-50%);
        width: 60%;
        position: absolute;
        left: 50%;
        top: 0;

        img {
          top: 0;
          width: 100%;
        }
      }

      .profile_page40_goku_img {
        transform: translateX(-50%);
        width: 60%;
        position: absolute;
        left: 50%;
        top: 0;

        img {
          top: 0;
          width: 80%;
          padding-top: 19.5%;
          padding-left: 1.85%;
        }
      }
    }
    .Profile_page40_container_footer {
      padding-top: 32%;
      text-align: center;
      ul {
        display: flex;
        justify-content: space-evenly;
        li {
          display: flex;
          flex-direction: column;

          span {
            color: #0066cc;
          }

          p {
            color: white;
          }
        }
      }
    }
  }

  .Profile_slide_btn {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 90%;
    .Profile_slide_btn_prev {
      position: absolute;
      left: 2%;

      .profile_prev {
      }
    }

    .Profile_slide_btn_next {
      position: absolute;
      right: 2%;

      .profile_next {
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .Profile_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;

    a {
      color: black;
      cursor: pointer;
    }

    .Profile_menu {
      display: none;
    }

    .Profile_center {
      padding-top: 10%;
      .Profile_center_bg {
        .profile_page1_bg {
          width: 80%;
        }
      }
      .Profile_center_text {
        .profile_page1_text {
          width: 40%;
        }
      }
    }

    .Profile_bottom {
      .Profile_bottom_circle_bottom_bg {
        .circle_bottom_bg {
          width: 100%;
        }
      }

      .Profile_bottom_illustration {
        .illustration {
          width: 75%;
        }
      }
    }

    .Profile_cdt {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      .cdt_icon {
        position: absolute;
        img {
          width: 70%;
        }
      }
    }

    .Profile_goku_img {
      .Profile_goku_img_left {
        position: absolute;
        top: 45%;
        left: 15%;
        width: 20%;
        .profile_page1_goku_img_left {
          width: 100%;
          border-radius: 22px;
          box-shadow: -10px 10px #e0eef7;
        }
      }
      .Profile_goku_img_right {
        position: absolute;
        top: 40%;
        left: 60%;
        width: 20%;
        .profile_page1_goku_img_right {
          width: 100%;
          border-radius: 24px;
          box-shadow: 10px 10px #e0eef7;
        }
      }
    }

    .Profile_project {
      width: 120px;
      height: 120px;
      top: 25%;
      left: 5%;

      h1 {
        font-weight: bold;
        font-size: 30px;
      }
    }

    .Profile_contact {
      border-radius: 32px;
      border: 1px solid rgba($color: white, $alpha: 0.2);
      padding: 24px;
      top: 90%;
      left: 50%;
      background-color: rgba($color: white, $alpha: 0.1);
      backdrop-filter: blur(16px);
      transform: translateX(-50%) translateY(-50%);

      .Profile_contact_img {
        .profile_page1_goku_icon {
        }
      }

      .Profile_contact_content {
        color: white;
        padding: 0 20px;
        h3 {
        }
        span {
        }
      }

      .Profile_contact_contact {
        width: 100px;
        // padding: 4px 0;
        a {
          padding: 8px 20px;
        }
      }
    }
  }

  .Profile_page2_wrapper {
    .Profile_page2_nav {
      height: 5%;

      span {
        color: white;
      }
    }

    .Profile_page2_body {
      .Profile_page2_body_tv {
        .profile_page2_tv {
          width: 100%;
        }
      }

      .Profile_page2_body_camera {
        .profile_page2_camera {
          width: 25%;
        }
      }

      .Profile_page2_body_content {
        .page2_body_content_title {
          font-size: 5em;
        }

        .page2_body_content {
          width: 45%;
          color: #777e90;
          font-weight: bold;
          // font-family: 'SF Pro';
        }

        .page2_body_content_since {
          color: #777e90;
          font-style: italic;
          font-size: 14px;
        }
      }
    }
  }

  .Profile_page3_wrapper {
    .Profile_page3_container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding-top: 40%;
      .Profile_page3_title {
        h1 {
          font-size: 50px;
          font-weight: bold;
          line-height: 50px;
        }

        p {
          margin-top: 20px;
          font-size: 16px;
        }
      }

      .Profile_page3_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 4% 10%;

        .page3_body_content {
          display: flex;
          flex-direction: column;
          justify-content: start;
          text-align: left;
          background-color: white;
          margin: 20px;
          padding: 30px 20px;
          border-radius: 18px;
          box-shadow: 5px 5px 5px rgba($color: black, $alpha: 0.1);
          width: 70%;

          .page3_body_content_icon {
            margin-bottom: 20px;
          }

          p {
            font-weight: 600;
            font-size: 20px;
          }
        }
      }

      .Profile_more_btn {
      }
    }
  }

  .Profile_page4_wrapper {
    overflow: auto;
    .Profile_page4_container {
      padding: 0;

      img {
        padding: 0 5px;
        width: 50%;
      }
    }

    .Profile_page4_footer {
      position: absolute;
      top: 95%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);

      p {
        color: #0066cc;
        span {
          font-weight: 600;
        }
      }
    }
  }

  .Profile_page5_wrapper {
    .Profile_page5_container {
      h1 {
      }

      img {
        width: 100%;
      }
    }
  }

  .Profile_page6_wrapper,
  .Profile_page8_wrapper,
  .Profile_page10_wrapper,
  .Profile_page13_wrapper,
  .Profile_page15_wrapper {
    .Profile_page6_container,
    .Profile_page8_container,
    .Profile_page10_container,
    .Profile_page13_container,
    .Profile_page15_container {
      img {
        width: 100%;
      }

      p {
        color: #0066cc;
        position: absolute;
        top: 90%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        span {
          font-weight: bold;
        }
      }
    }

    .Profile_slide_btn {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      top: 90%;
      .Profile_slide_btn_prev {
        position: absolute;
        left: 2%;

        .profile_prev {
        }
      }

      .Profile_slide_btn_next {
        position: absolute;
        right: 2%;

        .profile_next {
        }
      }
    }
  }

  .Profile_page7_wrapper,
  .Profile_page11_wrapper,
  .Profile_page16_wrapper {
    overflow: auto;

    .Profile_page7_container,
    .Profile_page11_container,
    .Profile_page16_container {
      padding-top: 10%;
    }

    .Profile_page7_container,
    .Profile_page11_container,
    .Profile_page16_container {
      flex-direction: column;

      .Profile_page7_title,
      .Profile_page11_title,
      .Profile_page16_title {
        h1 {
          font-size: 4em;
          font-weight: bold;
          line-height: 50px;
        }

        p {
          margin-top: 20px;
          font-size: 20px;
        }
      }

      .Profile_page7_body,
      .Profile_page11_body,
      .Profile_page16_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 4% 0%;
        overflow: auto;

        .page7_body_content,
        .page11_body_content,
        .page16_body_content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: left;
          background-color: white;
          margin: 10px;
          padding: 0px 0px;
          width: 100%;

          .page7_body_content_img,
          .page11_body_content_img,
          .page16_body_content_img {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            width: 100%;
            img {
              width: 90%;
              border-radius: 16px;
            }
          }

          p {
            font-weight: 600;
            font-size: 20px;
          }

          span {
            color: #777e90;
          }
        }
      }

      .Profile_more_btn {
        padding: 12px 20px;
        color: white;
        border-radius: 999px;
        background-color: #0071e3;
        cursor: pointer;
      }
    }
  }

  .Profile_page9_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;

    .Profile_page9_container_wrapper {
      flex-direction: column;
      align-items: center;
      padding: 0px;
      .Profile_page9_container_left {
        padding-top: 10%;
        width: 80%;
        height: 30%;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;

        .Profile_page9_title {
          // line-height: 64px;
          h1 {
            font-size: 4em;
            font-weight: bold;
            line-height: 50px;
          }

          p {
            margin-top: 20px;
            font-size: 20px;
          }
        }

        .Profile_more_btn {
          padding: 12px 20px;
          color: white;
          border-radius: 999px;
          background-color: #0071e3;
          cursor: pointer;
        }
      }

      .Profile_page9_container_right {
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .page9_container_right_top {
          text-align: center;
          .page9_container_right_top_img {
            img {
              width: 80%;
            }
          }
          p {
            font-weight: bold;
            padding: 0px 0;

            span {
              padding-left: 10px;
              font-weight: normal;
              font-size: 14px;
              color: #777e90;
            }
          }
        }

        .page9_container_right_bottom {
          display: flex;
          justify-content: space-evenly;
          text-align: center;

          .page9_container_right_bottom_content {
            // line-height: 0px;
            .page9_container_right_bottom_img {
              img {
                width: 60%;
              }
            }

            p {
              font-weight: bold;
              // padding: 10px 0;
              padding-top: 10px;
              margin-bottom: 0;
            }

            span {
              font-weight: normal;
              font-size: 14px;
              color: #777e90;
            }
          }
        }
      }
    }
  }

  .Profile_page12_wrapper {
    .Profile_page12_container {
      .Profile_page12_title {
        // line-height: 64px;
        width: 80%;
        h1 {
          font-size: 3em;
        }

        p {
          margin-top: 0px;
          font-size: 20px;
        }
      }

      .Profile_page12_body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        padding: 0% 0%;

        .page12_body_content:nth-child(4) {
          grid-column: unset;
        }

        .page12_body_content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: white;
          margin: 10px;
          width: 100%;

          .page12_body_content_img {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            width: 80%;
            img {
              width: 90%;
              border-radius: 16px;
            }
          }

          p {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 0px;
          }

          span {
            color: #777e90;
          }
        }
      }
    }
  }

  .Profile_page14_wrapper {
    overflow: auto;

    .Profile_page14_container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding-top: 60%;

      .Profile_page14_title {
        // line-height: 64px;
        width: 80%;
        h1 {
          font-size: 4em;
          font-weight: bold;
          line-height: 50px;
        }

        p {
          margin-top: 20px;
          font-size: 20px;
        }
      }

      .Profile_page14_body {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        padding: 0% 10%;

        .page14_body_content:nth-child(3) {
          // grid-column: 1/3;
        }

        .page14_body_content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: white;
          margin: 10px;
          width: 100%;

          .page14_body_content_img {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            width: 100%;
            img {
              width: 90%;
              border-radius: 16px;
            }
          }

          p {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 0px;
          }
        }
      }
      .Profile_more_btn {
        padding: 12px 20px;
        color: white;
        border-radius: 999px;
        background-color: #0071e3;
        cursor: pointer;
      }
    }
  }

  .Profile_page17_wrapper {
    .Profile_page17_container {
      padding: 0px 0%;

      h1 {
        font-size: 4em;
        background: linear-gradient(
          to right,
          #1e3791 0%,
          #2948b1 33%,
          #3153c6 66%,
          #385fda 100%
        );
        background-clip: text;
        color: transparent;
        padding-bottom: 20px;
      }

      .Profile_page17_body {
        padding: 50px 0;

        h2 {
          width: 80%;
          margin-bottom: 4%;
        }

        .page17_body_imac_content {
          width: 100%;

          .profile_imac_iframe {
            width: 100%;
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 0;
            // padding-bottom: 18%;
            // padding-top: 1%;
            iframe {
              // position: absolute;
              // padding-top: 20px;
              width: 80%;
              height: 100%;
              padding: 3.5%;
            }
          }

          .profile_imac_img {
            width: 100%;
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;

            // position: absolute;
            img {
              // position: absolute;
              // padding-top: 20px;
              width: 80%;
            }
          }
        }
      }
    }
  }

  .Profile_page18_wrapper {
    .Profile_page18_container {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0%;

      .page18_content_left {
        width: 80%;
        text-align: center;
        padding-top: 10%;

        h1 {
          font-weight: bold;
          font-size: 4em;
          margin-bottom: 5%;
        }

        h3 {
          padding-left: 0%;
        }
      }

      .page18_content_right {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }

  .Profile_page19_wrapper {
    .Profile_page19_container {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0%;
      padding-top: 5%;

      .page19_content_left {
        display: flex;

        position: relative;
        width: 100%;
        height: 50%;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        img:nth-child(1) {
          z-index: 1;
        }

        img:nth-child(2) {
          padding: 20px 40px;
        }
      }

      .page19_content_right {
        width: 100%;
        height: 40%;
        text-align: left;
        padding-left: 15%;
        margin-top: 5%;

        h1 {
          font-weight: bold;
          font-size: 4em;
        }

        p {
          padding-right: 5%;
        }

        .page19_right_btn_container {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
          height: 50%;

          .profile_page19_btn {
            display: flex;
            align-items: center;
            text-align: center;
            cursor: pointer;

            .profile_page19_btn_left {
              height: 50px;
              width: 50px;
              border-radius: 50%;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              border: 1px solid black;
            }

            h3 {
              padding-left: 5%;
              font-weight: bold;
              font-size: 25px;
            }
          }

          .btn_left_active,
          .profile_page19_btn:hover {
            .profile_page19_btn_left {
              background: linear-gradient(to right, #2e92ee 0%, #be6bff 100%);
            }

            h3 {
              background: linear-gradient(to right, #2e92ee 0%, #be6bff 100%);
              background-clip: text;
              color: transparent;
            }
          }
        }
      }
    }
  }

  .Profile_page23_wrapper,
  .Profile_page30_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;

    .Profile_page23_container,
    .Profile_page30_container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0%;

      .page23_content_left,
      .page30_content_left {
        position: relative;
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        img:nth-child(1) {
          z-index: 0;
        }

        img:nth-child(2) {
          padding: 10px 40px;
        }
      }

      .page23_content_right,
      .page30_content_right {
        width: 100%;
        text-align: center;
        margin-bottom: 5%;
        // padding-right: 0px;
        // align-items: end;

        h1 {
          font-weight: bold;
          width: 100%;
          font-size: 4em;
        }

        p {
          padding-left: 0%;
        }

        .page23_right_btn_container,
        .page30_right_btn_container {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: end;
          width: 100%;
          height: 50%;

          .profile_page23_btn,
          .profile_page30_btn {
            display: flex;
            align-items: center;
            text-align: right;
            justify-content: end;
            width: 100%;
            cursor: pointer;

            .profile_page23_btn_left,
            .profile_page30_btn_left {
              height: 50px;
              width: 50px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid black;
              margin-left: 5%;
              margin: 2%;
            }

            h3 {
              font-weight: bold;
            }
          }

          .btn_left_active,
          .profile_page23_btn:hover,
          .profile_page30_btn:hover {
            .profile_page23_btn_left,
            .profile_page30_btn_left {
              background: linear-gradient(to right, #2eee86 0%, #26beee 100%);
            }

            h3 {
              background: linear-gradient(to right, #2eee86 0%, #26beee 100%);
              background-clip: text;
              color: transparent;
            }
          }
        }
      }
    }
  }

  .Profile_page26_wrapper,
  .Profile_page33_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;

    .Profile_page26_container,
    .Profile_page33_container {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0%;
      padding-top: 10%;

      .page26_content_left,
      .page33_content_left {
        position: relative;
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img,
        video {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        img:nth-child(1) {
          z-index: 1;
        }

        img:nth-child(2) {
          padding: 10px 40px;
        }

        video {
          padding: 3% 8%;
          object-fit: fill;
        }
      }

      .page26_content_right,
      .page33_content_right {
        margin-top: 5%;
        width: 100%;
        height: 40%;
        text-align: left;
        padding-left: 10%;

        h1 {
          font-weight: bold;
        }

        p {
          padding-right: 5%;
          display: none;
        }

        .page26_right_btn_container,
        .page33_right_btn_container {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
          height: 80%;

          .profile_page26_btn,
          .profile_page33_btn {
            display: flex;
            align-items: center;
            cursor: pointer;
            text-align: left;
            justify-content: start;
            width: 100%;

            .profile_page26_btn_left,
            .profile_page33_btn_left {
              height: 50px;
              width: 50px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid black;
              margin-right: 5%;
            }

            h3 {
              font-weight: bold;
            }

            h4 {
              display: none;
            }
          }

          .btn_left_active,
          .profile_page26_btn:hover,
          .profile_page33_btn:hover {
            .profile_page26_btn_left,
            .profile_page33_btn_left {
              background: linear-gradient(to right, #2e92ee 0%, #be6bff 100%);
            }

            h3 {
              background: linear-gradient(to right, #2e92ee 0%, #be6bff 100%);
              background-clip: text;
              color: transparent;
            }
          }
        }
      }
    }
  }

  .Profile_page35_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;

    .Profile_page35_container {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0%;

      .page35_content_left {
        position: relative;
        width: 100%;
        height: 60%;
        margin-top: 10%;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        img:nth-child(1) {
          z-index: 0;
        }

        img:nth-child(2) {
          padding: 10px 40px;
        }
      }

      .page35_content_right {
        width: 100%;
        text-align: center;
        padding-right: 50px;
        // align-items: end;

        h1 {
          font-weight: bold;
          font-size: 4em;
        }

        p {
          padding-left: 5%;
        }
      }
    }
  }

  .Profile_page36_wrapper {
    .Profile_page36_container {
      .Profile_page36_title {
        position: unset;
        top: 8%;
        h1 {
          font-size: 4em;
          font-weight: bold;
          line-height: 50px;
        }
      }

      .Profile_page36_body {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 4% 0%;

        .page36_body_content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: left;
          background-color: white;
          margin: 10px;
          padding: 0px 0px;
          width: 70%;

          .page36_body_content_img {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            width: 100%;
            img {
              width: 90%;
              border-radius: 16px;
            }
          }

          p {
            font-weight: 600;
            font-size: 20px;
          }

          span {
            color: #777e90;
          }
        }

        .page36_body_content:nth-child(1) {
          // margin-bottom: 100px;
        }
        .page36_body_content:nth-child(2) {
          margin-top: 150px;
        }
      }
    }
  }

  .Profile_page38_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;

    .Profile_page38_container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .profile_page38_img1 {
        width: 150%;
      }

      .profile_page38_img2 {
        margin-top: 10%;
        width: 80%;
      }
    }
  }

  .Profile_page39_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: auto;

    .Profile_page39_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0%;
      padding-top: 10%;

      h1 {
        font-size: 4em;
        font-weight: bold;
      }

      .Profile_page39_container_img {
        padding: 2%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        img:nth-child(1) {
          width: 60%;
          padding: 20px;
        }

        img:nth-child(2) {
          width: 60%;
          padding: 20px;
        }
      }
    }
  }

  .Profile_page40_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: #141416;

    .Profile_page40_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 5%;
      .Profile_page40_container_content {
        width: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        position: relative;

        .profile_page40_bg_img {
          transform: translateX(-50%);
          width: 70%;
          position: absolute;
          left: 50%;
          top: 0;

          img {
            top: 0;
            width: 100%;
          }
        }

        .profile_page40_goku_img {
          transform: translateX(-50%);
          width: 70%;
          position: absolute;
          left: 50%;
          top: 0;

          img {
            top: 0;
            width: 80%;
            padding-top: 19.5%;
            padding-left: 1.85%;
          }
        }
      }
      .Profile_page40_container_footer {
        padding-top: 50%;
        ul {
          display: flex;
          justify-content: space-evenly;
          li {
            display: flex;
            flex-direction: column;
            text-align: center;

            span {
              color: #0066cc;
            }

            p {
              color: white;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Profile_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;

    a {
      color: black;
      cursor: pointer;
    }

    .Profile_menu {
      display: none;
    }

    .Profile_center {
      padding-top: 10%;
      .Profile_center_bg {
        .profile_page1_bg {
          width: 80%;
        }
      }
      .Profile_center_text {
        .profile_page1_text {
          width: 40%;
        }
      }
    }

    .Profile_bottom {
      .Profile_bottom_circle_bottom_bg {
        .circle_bottom_bg {
          width: 100%;
        }
      }

      .Profile_bottom_illustration {
        .illustration {
          width: 75%;
        }
      }
    }

    .Profile_cdt {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      .cdt_icon {
        position: absolute;
        img {
          width: 70%;
        }
      }
    }

    .Profile_goku_img {
      .Profile_goku_img_left {
        position: absolute;
        top: 45%;
        left: 15%;
        width: 20%;
        .profile_page1_goku_img_left {
          width: 100%;
          border-radius: 22px;
          box-shadow: -10px 10px #e0eef7;
        }
      }
      .Profile_goku_img_right {
        position: absolute;
        top: 40%;
        left: 60%;
        width: 20%;
        .profile_page1_goku_img_right {
          width: 100%;
          border-radius: 24px;
          box-shadow: 10px 10px #e0eef7;
        }
      }
    }

    .Profile_project {
      width: 120px;
      height: 120px;
      top: 25%;
      left: 5%;

      h1 {
        font-weight: bold;
        font-size: 30px;
      }
    }

    .Profile_contact {
      border-radius: 32px;
      border: 1px solid rgba($color: white, $alpha: 0.2);
      padding: 24px;
      top: 90%;
      left: 50%;
      background-color: rgba($color: white, $alpha: 0.1);
      backdrop-filter: blur(16px);
      transform: translateX(-50%) translateY(-50%);

      .Profile_contact_img {
        .profile_page1_goku_icon {
        }
      }

      .Profile_contact_content {
        color: white;
        padding: 0 20px;
        h3 {
        }
        span {
        }
      }

      .Profile_contact_contact {
        width: 100px;
        // padding: 4px 0;
        a {
          padding: 8px 20px;
        }
      }
    }
  }

  .Profile_page2_wrapper {
    .Profile_page2_nav {
      height: 5%;

      span {
        color: white;
      }
    }

    .Profile_page2_body {
      .Profile_page2_body_tv {
        .profile_page2_tv {
          width: 100%;
        }
      }

      .Profile_page2_body_camera {
        .profile_page2_camera {
          width: 45%;
        }
      }

      .Profile_page2_body_content {
        .page2_body_content_title {
          font-size: 3em;
        }

        .page2_body_content {
          width: 45%;
          color: #777e90;
          font-weight: bold;
          font-size: 12px;
          // font-family: 'SF Pro';
        }

        .page2_body_content_since {
          color: #777e90;
          font-style: italic;
          font-size: 14px;
        }
      }
    }
  }

  .Profile_page3_wrapper {
    .Profile_page3_container {
      padding-top: 60%;
      .Profile_page3_title {
        h1 {
          font-size: 3em;
          font-weight: bold;
          line-height: 50px;
        }

        p {
          margin-top: 20px;
          font-size: 16px;
        }
      }

      .Profile_page3_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 4% 10%;

        .page3_body_content {
          display: flex;
          flex-direction: column;
          justify-content: start;
          text-align: left;
          background-color: white;
          margin: 10px;
          padding: 30px 20px;
          border-radius: 18px;
          box-shadow: 5px 5px 5px rgba($color: black, $alpha: 0.1);
          width: 80%;

          .page3_body_content_icon {
            margin-bottom: 20px;

            img {
              width: 15%;
            }
          }

          p {
            font-weight: 600;
            font-size: 14px;
          }
        }
      }

      .Profile_more_btn {
      }
    }
  }

  .Profile_page4_wrapper {
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .Profile_page4_container {
      padding: 0;
      flex-direction: column;
      width: 100%;
      margin-bottom: 15%;

      img {
        padding: 5px 5px;
        width: 70%;
      }
    }

    .Profile_page4_footer {
      position: fixed;
      top: 92%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
      text-align: center;

      p {
        color: white;
        font-size: 12px;
        span {
          font-weight: 600;
        }
      }
    }
  }

  .Profile_page5_wrapper {
    .Profile_page5_container {
      h1 {
        font-size: 3em;
        position: fixed;
        top: 15%;
      }

      img {
        margin-top: 10%;
        width: 120%;
      }
    }
  }

  .Profile_page6_wrapper,
  .Profile_page8_wrapper,
  .Profile_page10_wrapper,
  .Profile_page13_wrapper,
  .Profile_page15_wrapper {
    .Profile_page6_container,
    .Profile_page8_container,
    .Profile_page10_container,
    .Profile_page13_container,
    .Profile_page15_container {
      img {
        width: 100%;
        transform: translateY(-50%);
      }

      p {
        color: #0066cc;
        width: 65%;
        text-align: center;
        position: fixed;
        top: 90%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        span {
          font-weight: bold;
        }
      }
    }
  }

  .Profile_page7_wrapper,
  .Profile_page11_wrapper,
  .Profile_page16_wrapper {
    overflow: auto;

    .Profile_page7_container,
    .Profile_page11_container {
      padding-top: 20%;
    }

    .Profile_page16_container {
      padding-top: 0%;
    }

    .Profile_page7_container,
    .Profile_page11_container,
    .Profile_page16_container {
      flex-direction: column;

      .Profile_page7_title,
      .Profile_page11_title,
      .Profile_page16_title {
        h1 {
          font-size: 3em;
          font-weight: bold;
          line-height: 50px;
        }

        p {
          margin-top: 10px;
          font-size: 16px;
        }
      }

      .Profile_page7_body,
      .Profile_page11_body,
      .Profile_page16_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0% 0%;
        overflow: auto;

        .page7_body_content,
        .page11_body_content,
        .page16_body_content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: left;
          background-color: white;
          margin: 0px;
          padding: 0px 0px;
          width: 100%;
          margin-bottom: 20px;

          .page7_body_content_img,
          .page11_body_content_img,
          .page16_body_content_img {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0px;
            width: 100%;
            img {
              width: 90%;
              border-radius: 16px;
            }
          }

          p {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 0;
          }

          span {
            color: #777e90;
          }
        }
      }

      .Profile_more_btn {
        padding: 12px 20px;
        color: white;
        border-radius: 999px;
        background-color: #0071e3;
        cursor: pointer;
        display: none;
      }
    }
  }

  .Profile_page9_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;

    .Profile_page9_container_wrapper {
      flex-direction: column;
      align-items: center;
      padding: 0px;
      .Profile_page9_container_left {
        padding-top: 15%;
        width: 100%;
        height: 35%;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;

        .Profile_page9_title {
          // line-height: 64px;
          h1 {
            font-size: 50px;
          }

          p {
            margin-top: 0px;
            font-size: 16px;
          }
        }

        .Profile_more_btn {
          padding: 12px 20px;
          color: white;
          border-radius: 999px;
          background-color: #0071e3;
          cursor: pointer;
          display: none;
        }
      }

      .Profile_page9_container_right {
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: unset;
        align-items: center;
        padding-bottom: 20%;
        overflow: auto;
        .page9_container_right_top {
          text-align: center;
          .page9_container_right_top_img {
            img {
              width: 80%;
            }
          }
          p {
            font-weight: bold;
            padding: 0px 0;

            span {
              padding-left: 10px;
              font-weight: normal;
              font-size: 14px;
              color: #777e90;
            }
          }
        }

        .page9_container_right_bottom {
          display: flex;
          justify-content: space-evenly;
          text-align: center;

          .page9_container_right_bottom_content {
            // line-height: 0px;
            .page9_container_right_bottom_img {
              img {
                width: 60%;
              }
            }

            p {
              font-weight: bold;
              // padding: 10px 0;
              padding-top: 10px;
              margin-bottom: 0;
            }

            span {
              font-weight: normal;
              font-size: 14px;
              color: #777e90;
            }
          }
        }
      }
    }
  }

  .Profile_page12_wrapper {
    .Profile_page12_container {
      margin-top: 17%;
      .Profile_page12_title {
        // line-height: 64px;
        width: 100%;
        h1 {
          font-size: 3em;
        }

        p {
          margin-top: 0px;
          font-size: 20px;
        }
      }

      .Profile_page12_body {
        display: grid;
        overflow: auto;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        padding: 0% 10%;

        .page12_body_content:nth-child(4) {
          grid-column: unset;
        }

        .page12_body_content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: white;
          margin: 0px;
          width: 100%;
          margin-bottom: 10px;

          .page12_body_content_img {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            width: 100%;
            img {
              width: 100%;
              border-radius: 16px;
            }
          }

          p {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 0px;
          }

          span {
            color: #777e90;
          }
        }
      }
    }
  }

  .Profile_page14_wrapper {
    overflow: auto;

    .Profile_page14_container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding-top: 25%;

      .Profile_page14_title {
        // line-height: 64px;
        width: 100%;
        h1 {
          font-size: 3em;
          font-weight: bold;
          line-height: 50px;
        }

        p {
          margin-top: 20px;
          font-size: 20px;
        }
      }

      .Profile_page14_body {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        padding: 0% 5%;

        .page14_body_content:nth-child(3) {
          // grid-column: 1/3;
        }

        .page14_body_content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: white;
          margin: 0px;
          margin-bottom: 10px;
          width: 100%;

          .page14_body_content_img {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            width: 100%;
            img {
              width: 100%;
              border-radius: 16px;
            }
          }

          p {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 0px;
          }
        }
      }
      .Profile_more_btn {
        padding: 12px 20px;
        color: white;
        border-radius: 999px;
        background-color: #0071e3;
        cursor: pointer;
      }
    }
  }

  .Profile_page17_wrapper {
    .Profile_page17_container {
      padding: 0px 5%;

      h1 {
        font-size: 3em;
        background: linear-gradient(
          to right,
          #1e3791 0%,
          #2948b1 33%,
          #3153c6 66%,
          #385fda 100%
        );
        background-clip: text;
        color: transparent;
        padding-bottom: 20px;
      }

      .Profile_page17_body {
        padding: 20px 10px;

        h2 {
          width: 100%;
          font-weight: bold;
          font-size: 1.5em;
          margin-bottom: 0%;
        }

        .page17_body_imac_content {
          width: 100%;

          .profile_imac_iframe {
            width: 100%;
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 0;
            // padding-bottom: 18%;
            // padding-top: 1%;
            iframe {
              // position: absolute;
              // padding-top: 20px;
              width: 100%;
              height: 100%;
              padding: 3.5%;
            }
          }

          .profile_imac_img {
            width: 100%;
            height: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;

            // position: absolute;
            img {
              // position: absolute;
              // padding-top: 20px;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .Profile_page18_wrapper {
    .Profile_page18_container {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 7%;

      .page18_content_left {
        width: 100%;
        text-align: center;
        padding-top: 10%;

        h1 {
          font-weight: bold;
          font-size: 3em;
        }

        p {
          padding-left: 0%;
          padding-top: 5%;
          font-size: 1.5em;
          font-weight: bold;
        }
      }

      .page18_content_right {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }

  .Profile_page19_wrapper {
    .Profile_page19_container {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0%;
      padding-top: 5%;

      .page19_content_left {
        display: flex;
        position: relative;
        width: 100%;
        height: 40%;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        img:nth-child(1) {
          z-index: 1;
        }

        img:nth-child(2) {
          padding: 20px 20px;
        }
      }

      .page19_content_right {
        width: 100%;
        height: 40%;
        text-align: left;
        padding-left: 15%;
        margin-top: 5%;

        h1 {
          font-weight: bold;
          font-size: 3em;
        }

        p {
          padding-right: 0%;
        }

        .page19_right_btn_container {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
          height: 50%;

          .profile_page19_btn {
            display: flex;
            align-items: center;
            text-align: center;
            cursor: pointer;

            .profile_page19_btn_left {
              height: 50px;
              width: 50px;
              border-radius: 50%;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              border: 1px solid black;
            }

            h3 {
              padding-left: 5%;
              font-weight: bold;
              font-size: 1em;
            }
          }

          .btn_left_active,
          .profile_page19_btn:hover {
            .profile_page19_btn_left {
              background: linear-gradient(to right, #2e92ee 0%, #be6bff 100%);
            }

            h3 {
              background: linear-gradient(to right, #2e92ee 0%, #be6bff 100%);
              background-clip: text;
              color: transparent;
            }
          }
        }
      }
    }
  }

  .Profile_page23_wrapper,
  .Profile_page30_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;

    .Profile_page23_container,
    .Profile_page30_container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0%;

      .page23_content_left,
      .page30_content_left {
        position: relative;
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img,
        video {
          position: absolute;
          width: 100%;
          height: 70%;
        }

        img:nth-child(1) {
          z-index: 0;
        }

        img:nth-child(2) {
          padding: 10px 20px;
          z-index: -1;
        }
      }

      .page23_content_right,
      .page30_content_right {
        width: 100%;
        // height: 20%;
        text-align: center;
        margin-bottom: 0%;
        padding-right: 0px;
        // align-items: end;

        h1 {
          font-weight: bold;
          width: 100%;
          font-size: 3em;
        }

        p {
          padding-left: 0%;
        }

        .page23_right_btn_container,
        .page30_right_btn_container {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: end;
          width: 100%;
          height: 50%;

          .profile_page23_btn,
          .profile_page30_btn {
            display: flex;
            align-items: center;
            text-align: right;
            justify-content: end;
            width: 100%;
            cursor: pointer;
            margin-right: 10%;

            .profile_page23_btn_left,
            .profile_page30_btn_left {
              height: 50px;
              width: 50px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid black;
              margin-left: 5%;
              margin: 2%;
            }

            h3 {
              font-weight: bold;
              font-size: 1em;
            }
          }

          .btn_left_active,
          .profile_page23_btn:hover,
          .profile_page30_btn:hover {
            .profile_page23_btn_left,
            .profile_page30_btn_left {
              background: linear-gradient(to right, #2eee86 0%, #26beee 100%);
            }

            h3 {
              background: linear-gradient(to right, #2eee86 0%, #26beee 100%);
              background-clip: text;
              color: transparent;
            }
          }
        }
      }
    }
  }

  .Profile_page26_wrapper,
  .Profile_page33_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;

    .Profile_page26_container,
    .Profile_page33_container {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0%;

      .page26_content_left,
      .page33_content_left {
        position: relative;
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img,
        video {
          position: absolute;
          width: 100%;
          height: 70%;
        }

        img:nth-child(1) {
          z-index: 1;
        }

        img:nth-child(2) {
          padding: 10px 20px;
        }
      }

      .page26_content_right,
      .page33_content_right {
        margin-top: 0%;
        width: 100%;
        // height: 40%;
        text-align: left;
        padding-left: 10%;

        h1 {
          font-weight: bold;
          font-size: 3em;
        }

        p {
          padding-right: 5%;
          display: none;
        }

        .page26_right_btn_container,
        .page33_right_btn_container {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
          height: 60%;

          .profile_page26_btn,
          .profile_page33_btn {
            display: flex;
            align-items: center;
            cursor: pointer;
            text-align: left;
            justify-content: start;
            width: 100%;

            .profile_page26_btn_left,
            .profile_page33_btn_left {
              height: 50px;
              width: 50px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid black;
              margin-right: 5%;
            }

            h3 {
              font-weight: bold;
              font-size: 1em;
            }

            h4 {
              display: none;
            }
          }

          .btn_left_active,
          .profile_page26_btn:hover,
          .profile_page33_btn:hover {
            .profile_page26_btn_left,
            .profile_page33_btn_left {
              background: linear-gradient(to right, #2e92ee 0%, #be6bff 100%);
            }

            h3 {
              background: linear-gradient(to right, #2e92ee 0%, #be6bff 100%);
              background-clip: text;
              color: transparent;
            }
          }
        }
      }
    }
  }

  .Profile_page35_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;

    .Profile_page35_container {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0%;

      .page35_content_left {
        position: relative;
        width: 100%;
        height: 50%;
        margin-top: 10%;

        img,
        video {
          position: absolute;
          width: 100%;
          height: 70%;
        }

        img:nth-child(1) {
          z-index: 1;
        }

        img:nth-child(2) {
          padding: 10px 20px;
        }
      }

      .page35_content_right {
        width: 100%;
        text-align: center;
        padding-right: 0px;
        // align-items: end;

        h1 {
          font-weight: bold;
          width: 100%;
          font-size: 3em;
        }

        p {
          padding-left: 0%;
          padding: 0 5%;
        }
      }
    }
  }

  .Profile_page36_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;

    .Profile_page36_container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding-top: 20%;

      .Profile_page36_title {
        position: unset;
        top: 8%;
        h1 {
          font-size: 3em;
          font-weight: bold;
          line-height: 50px;
        }

        p {
          margin-top: 20px;
          font-size: 20px;
        }
      }

      .Profile_page36_body {
        overflow: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10% 5%;

        .page36_body_content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: left;
          background-color: white;
          margin: 0px;
          padding: 0px 0px;
          width: 100%;
          margin-bottom: 20px;

          .page36_body_content_img {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            width: 100%;
            img {
              width: 100%;
              border-radius: 16px;
            }
          }

          p {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 0;
          }

          span {
            color: #777e90;
          }
        }

        .page36_body_content:nth-child(1) {
          // margin-bottom: 100px;
        }
        .page36_body_content:nth-child(2) {
          margin-top: 0;
        }
      }
    }
  }

  .Profile_page38_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;

    .Profile_page38_container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .profile_page38_img1 {
        width: 200%;
      }

      .profile_page38_img2 {
        margin-top: 10%;
        width: 100%;
      }
    }
  }

  .Profile_page39_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: auto;

    .Profile_page39_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0%;
      padding-top: 20%;

      h1 {
        font-size: 3em;
        font-weight: bold;
      }

      .Profile_page39_container_img {
        padding: 0%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        img:nth-child(1) {
          width: 75%;
          padding: 20px;
        }

        img:nth-child(2) {
          width: 75%;
          padding: 20px;
        }
      }
    }
  }

  .Profile_page40_wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: #141416;

    .Profile_page40_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0%;
      .Profile_page40_container_content {
        width: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        position: relative;

        .profile_page40_bg_img {
          transform: translateX(-50%);
          width: 100%;
          position: absolute;
          left: 50%;
          top: 0;

          img {
            top: 0;
            width: 100%;
          }
        }

        .profile_page40_goku_img {
          transform: translateX(-50%);
          width: 100%;
          position: absolute;
          left: 50%;
          top: 0;

          img {
            top: 0;
            width: 80%;
            padding-top: 19.5%;
            padding-left: 1.85%;
          }
        }
      }
      .Profile_page40_container_footer {
        padding-top: 50%;
        ul {
          display: grid;
          grid-template-columns: repeat(1, 1fr);

          li {
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
              color: #0066cc;
            }

            p {
              color: white;
            }
          }
        }
      }
    }
  }
}
